import React, { useState, useContext } from "react"
import { FirebaseContext } from "../components/Firebase"
import { Link, navigate } from "gatsby";

import { Form } from "../components/common/Form"
import { Input } from "../components/common/Input"
import { Button } from "../components/common/Button"
import { ErrorMessage } from "../components/common/ErrorMessage"


import Card from "../components/Layout/Card";
import Main from "../components/Layout/Main";
import FlexContainer from "../components/Layout/FlexContainer";
import FlexItem from "../components/Layout/FlexItem";
import SEO from "../components/seo"

const Register = ({ pageContext }) => {
    const [formValues, setFormValues] = useState(() => { return {email: '', password: '', confirmPassword: ''}})
    const { firebase } = useContext(FirebaseContext)
    const [errorMessage, setErrorMessage] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrorMessage('')
        if(formValues.password === formValues.confirmPassword) {
            firebase.register({email: formValues.email, password: formValues.password})
            .then(() => {
                navigate('/profile')
            })
            .catch(error => {
                setErrorMessage(error.message)
            })
        } else {
            setErrorMessage('Password and confirm password must match')
        }
    }

    const handleInputChange = (e) => {
        e.persist()
        setFormValues(currentValues => ({
            ...currentValues,
            [e.target.name]: e.target.value
        }))
    }

    pageContext.data = {}
    pageContext.data.desc_event = "Register"
    pageContext.data.name_event = "Register to zrce.app by zrce.eu"

    return( 
        <section>
            <SEO context={pageContext} />
            <Main>
                <FlexContainer>
                    <FlexItem>
                        <Card>
                            <h2>Register</h2>
                            <p>to zrce.app</p>
                        </Card>
                        <Card>
                            <Form onSubmit={handleSubmit}>
                                <Input value={formValues.email} name="email" onChange={handleInputChange} placeholder="email" type="email" required />
                                <Input value={formValues.password} name="password" onChange={handleInputChange} placeholder="password" type="password" required minLength={6} />
                                <Input value={formValues.confirmPassword} name="confirmPassword" onChange={handleInputChange} placeholder="confirm password" type="password" required minLength={6} />
                                {!!errorMessage &&
                                    <ErrorMessage>
                                        {errorMessage}
                                    </ErrorMessage>
                                }
                                <Button type="submit">
                                    Register
                                </Button>
                            </Form> 
                        </Card>
                        <Card>
                            <p><small>We won't send spam. Please check the data protection statement.</small></p>
                        </Card>
                        <Card>
                            <h2>Already registered</h2>
                            <p>you are amazing</p>
                        </Card>
                        <Card>
                            <Link to="/login"><Button>Login here</Button></Link>
                        </Card>
                    </FlexItem>
                </FlexContainer>
            </Main>
        </section>
    )
}

export default Register
