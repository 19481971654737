import React from "react"
import styled from "styled-components";

const Gap = styled.div`

`

const CardLayout = styled.div`
    background-color: ${props => (props.backgroundcolor ? props.backgroundcolor : '#f4f4f4')};
    color: ${props => (props.color ? props.color : '#1d1d1f')};
`

const Content = styled.div`
    padding: 16px 16px
`

const Card = ({ children, backgroundcolor, color}) => {
  return (
    <Gap>
        <CardLayout color={color} backgroundcolor={backgroundcolor} data-sal="slide-right" data-sal-easing="ease" data-sal-delay="300">
            <Content>{children}</Content>
        </CardLayout>
    </Gap>
   )
}

export default Card