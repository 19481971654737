import styled from "styled-components";

export const ErrorMessage = styled.div`
    min-width: 100%;
    max-width: 100%;
    font-size: 16px;
    color: #ff0000;
    margin-bottom: 16px;
    padding: 0;
    border: none;
`
