import styled from "styled-components";

export const Input = styled.input`
    font-size: 26px;
    box-sizing: border-box;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 16px;
    padding: 0;
    border: none;
`
