import styled from "styled-components";

export const Button = styled.button`
    box-sizing: border-box;
    min-width: 100%;
    max-width: 100%;
    font-size: 26px;
    background-color: #52c2f0;
    color: #ffffff;
    padding: 0;
    border: none;
`
